import React, { useState } from 'react';
import logo from '../../images/landing/landing_logo.png';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // กำหนด state isDropdownOpen

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // เปลี่ยนสถานะของ dropdown เมื่อคลิก
  };

  return (
    <header>
      <nav className="navbar navbar-b navbar-dark navbar-trans navbar-expand-xl fixed-top nav-padding bg-dark pt-2 pb-2" id="sidebar-menu">
        <a className="navbar-brand p-0" href="">
          <img className="img-fluid" src={logo} alt="Logo" />
        </a>
        <button
          className="navbar-toggler navabr_btn-set custom_nav"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="navbar-collapse justify-content-center collapse hidenav" id="navbarDefault">
          <ul className="navbar-nav navbar_nav_modify" id="scroll-spy">
            <li className="nav-item">
              <a className="nav-link" href="https://sms.local-api.com/">บริการส่ง SMS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://chat.local-api.com/">API Chat</a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link dropdown-toggle"
                id="applicationsDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded={isDropdownOpen} // ใช้ state isDropdownOpen
                onClick={toggleDropdown} // เมื่อคลิกจะเรียกใช้ toggleDropdown
              >
                Applications
              </a>
              <div className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`} aria-labelledby="applicationsDropdown">
                <a className="dropdown-item" href="">Application 1</a>
                <a className="dropdown-item" href="">Application 2</a>
                <a className="dropdown-item" href="">Application 3</a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#documentation">ระบบงานบริษัท</a>
            </li>
          </ul>
        </div>
        <div className="contract-btn rounded-pill">
          <a className="nav-link js-scroll" href="https://line.me/ti/p/-9M6g67EVG" target="_blank" rel="noopener noreferrer">ติดต่อเรา</a>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
