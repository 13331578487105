import logo from './images/logo-local-api-com-80x80.png';
import arrowDecore from './images/landing/decore/arrow.svg';
import screen1 from './images/landing/screen1.png';
import htmlIcon from './images/landing/icon/html/html.png';
import angularIcon from './images/landing/icon/angular/angular.png';
import laravelIcon from './images/landing/icon/laravel/laravel.png';
import flaskIcon from './images/landing/stroke-icon/7.svg';
import reactIcon from './images/landing/icon/react/react.png';
import phpIcon from './images/landing/stroke-icon/8.svg';
import vectorImage from './images/landing/vectors/1.svg';
import tagIcon from './assets/svg/landing-icons.svg';
import ratingsIcon from './assets/svg/landing-icons.svg';
import userCircleIcon from './assets/svg/landing-icons.svg';
import gearIcon from './assets/svg/landing-icons.svg';
import nodeIcon from './images/landing/icon/node/1.png';
import cardsImage from './images/landing/cards.png';
import cartoon from './images/landing/cartoonboy.png';
import cartoon2 from './images/landing/cartoonboy2.png';
import './App.css';
import './assets/css/font-awesome.css';
import './assets/css/vendors/icofont.css';
import './assets/css/vendors/slick.css';
import './assets/css/vendors/slick-theme.css';
import './assets/css/vendors/animate.css';
import './assets/css/vendors/bootstrap.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';
import Navbar from './components/Navbar/Navbar';

function App() {
  return (
    <div className="App">
        <Navbar />

      <div className="landing-home">
        <div className="container-fluid">
          <div className="sticky-header">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-sm-10">
                <div className="content text-center">
                  <div>
                    <h6 className="content-title"><img className="arrow-decore" src={arrowDecore} alt="Arrow Decore" /><span className="sub-title">All Services API</span></h6>
                    <h1 className="wow fadeIn">รวมทุกอย่าง <span>สำหรับระบบงาน  API </span> ต่าง ๆ</h1>
                    <div className="btn-grp mt-4">
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="HTML"> 
                        <img src={htmlIcon} alt="HTML Icon" />
                      </a>
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="Angular 15"> 
                        <img src={angularIcon} alt="Angular Icon" />
                      </a>
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="Laravel 10"> 
                        <img src={laravelIcon} alt="Laravel Icon" />
                      </a>
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="Flask 2.2.2"> 
                        <img src={flaskIcon} alt="Flask Icon" />
                      </a>
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="React Context">
                        <img src={reactIcon} alt="React Icon" />
                      </a>
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="PHP"> 
                        <img src={phpIcon} alt="PHP Icon" />
                      </a>
                      <a className="wow pulse" href="#" target="_blank" rel="noopener noreferrer" data-bs-placement="top" title="Node"> 
                        <img src={nodeIcon} alt="Node Icon" />
                      </a>
                    </div>
                    <p className="mt-3 wow fadeIn">ขับเคลื่อนโดยระบบที่ทันสมัย และทรงพลัง ใช้งานง่าย สะดวก</p>                    
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-8 col-md-10">               
                <img className="screen1 img-fluid" src={screen1} alt="Screen 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add additional sections here following the same structure */}
      <section className="section-space premium-wrap">
      <div className="container"> 
        <ul className="decoration">
          <li className="flower-gif">
            <div className="mesh-loader">
              <div className="set-one">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
              <div className="set-two">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            </div>
          </li>
          <li className="wavy-gif">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251 38">
              <path fill="none" strokeWidth="10" strokeMiterlimit="10" d="M0,9C17.93,9,17.93,29,35.85,29S53.78,9,71.71,9s17.92,20,35.85,20S125.49,9,143.42,9s17.93,20,35.86,20S197.21,9,215.14,9,233.07,29,251,29"></path>
            </svg>
          </li>
        </ul>
        <div className="row justify-content-center">
          <div className="col-sm-12"> 
            <div className="landing-title">
              <h5 className="sub-title">Faster, Lighter & Dev. Friendly</h5>
              <h2> <span className="gradient-1">Benefits </span> of Local API</h2>
              <p>แพลตฟอร์มการทำงานแบบใหม่ ที่เชื่อมทุกบริการของคุณเข้าไว้ด้วยกัน</p>
            </div>
            <div className="vector-image cartoon-left">
                <img src={cartoon2} alt="cartoon2" />
            </div>
            <div className="vector-image cartoon-right"> 
              <img src={cartoon} alt="cartoon" />
            </div>
          </div>
          <div className="col-xxl-8">
            <div className="row g-lg-5 g-3">
              <div className="col-md-3 col-6">
                <div className="benefit-box pink">
                  <svg>
                    <use href={`${tagIcon}#tag`}></use>
                  </svg>
                  <h2 className="mb-0">4,620</h2>
                  <h6 className="mb-0">จำนวนระบบที่ดูแล</h6>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="benefit-box purple">
                  <svg>
                    <use href={`${ratingsIcon}#ratings`}></use>
                  </svg>
                  <h2 className="mb-0">88</h2>
                  <h6 className="mb-0">โปรเจ็คที่เคยดูแล</h6>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="benefit-box red">
                  <svg>
                    <use href={`${userCircleIcon}#user_circle`}></use>
                  </svg>
                  <h2 className="mb-0">3K</h2>
                  <h6 className="mb-0">ลูกค้าที่เคยใช้งาน</h6>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="benefit-box warning">
                  <svg>
                    <use href={`${gearIcon}#gear`}></use>
                  </svg>
                  <h2 className="mb-0">9 Years</h2>
                  <h6 className="mb-0">Development</h6>
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </div>
      </section>
      <section className="section-space cuba-demo-section bg-Widget pt-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 wow pulse">
              <div className="landing-title">
                <h5 className="sub-title">Our cards</h5>
                <h2>Introducing Our <span className="gradient-9">Cards collections</span></h2>
                <p>
                  Utilise the adaptable and practical templates to make your web application 
                  outstanding and more creative that provides you a competitive advantage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid o-hidden">
          <div className="row landing-cards">
            <div className="col-lg-12">
              <div className="animate-slider">
                <div className="cards-image"> <img src={cardsImage} alt="Cards" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default App;
